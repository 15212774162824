import React, { Component } from 'react';
import { createStyles, withStyles, Theme } from '@material-ui/core/styles';
import { Text, View, StyleSheet, Image, PDFViewer, PDFDownloadLink, pdf } from '@react-pdf/renderer';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/AddBoxOutlined';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import ViewIcon from '@material-ui/icons/VisibilityOutlined';
import SignIcon from '@material-ui/icons/EditOutlined';
import DatePicker from '../DatePicker';
import { IMe, IPersonal, IXForm, ICentroTrabajo, VIIIQuestion, IFirmante } from '../../types';
import { SelectorField, CheckBoxField, TextFieldInput } from '../FormsWidgets';
import {GetRole, GetEmpresa} from '../../helpers';
import TakeIcon from '@material-ui/icons/CheckCircleOutline';
import {cloneDeep, filter, find, findIndex} from 'lodash';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AddActionIcon from '@material-ui/icons/AddBox';
import IconButton from '@material-ui/core/IconButton';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import Paper from '@material-ui/core/Paper';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import PageViewIcon from '@material-ui/icons/Pageview';
import { GetConcatedDate, GetDatetimeFormat } from '../../vc_general';
import OrderService from '../../ApiClients/OrderService';
import LoaderBeauty from '../Material/activityProgress';
import CreateModal from '../CreateDialog';
import List from '../Material/MultiSelect';
import Loader from '../Loader';
import { ListChildComponentProps } from 'react-window';
import s1 from '../../Assets/widgets/s1.png';
import s2 from '../../Assets/widgets/s2.png';
import s3 from '../../Assets/widgets/s3.png';
import s4 from '../../Assets/widgets/s4.png';
import s5 from '../../Assets/widgets/s5.png';
import s6 from '../../Assets/widgets/s6.png';
import s7 from '../../Assets/widgets/s7.png';
import s8 from '../../Assets/widgets/s8.png';
import { Delete } from '@material-ui/icons';
import { PdfStyles } from './pdfStyles';
import AppNealia_prevent_icon from '../../Assets/nealia_prevent_icon.png';
import AppNealia_prevent from '../../Assets/nealia_prevent.png';
import { Document, Page } from '../Material/pdfRenderComponents';
import SignaturePanel from '../Material/SignatureControl';

const m01styles = ({
    button: {
        margin: 1,
        marginRight:15
    },
      leftIcon: {
        marginRight: 10,
    },
      rightIcon: {
        marginLeft: 1,
    },
      iconSmall: {
        fontSize: 20,
    },
    formControl: {
        margin: '1px',
        minWidth: 120,
        marginRight: 'auto',
    },
});

const styles = (theme: Theme) => createStyles({
    container: {
        padding: '10px',
        textAlign: 'center',
        overflowY:'auto'
    },
    image: {
        width: '100%',
        height: 'auto'
    },
    promotion: {
        //backgroundColor: '#f00',
        padding: '3px 15px',
        //color: '#fff',
        verticalAlign: 'middle',
    },
    root: {
    width: '100%',
    },
    paper: {
        width: '100%',
        marginTop: '5px',
        overflowX: 'auto',
        borderWidth: 2, 
        borderColor: '#00809b',
        borderStyle: 'solid',
    },
    table:{
        //tableLayout:'fixed'
        width: '100%',
    },
    row:{
        //wordWrap:'break-word'
        width:'100%',
        //backgroundColor:'#ddd'
    },
    heading: {
    fontSize: theme.typography.pxToRem(15),
    // fontWeight: theme.typography.fontWeightRegular,
    },
    item: {
        maxWidth:'200px',
        border: '2px solid #ccc',
        borderRadius: '15px',
        margin: '5px 10px',
        width: 'calc(100% - 20px)!important',
        boxShadow: '5px 5px 5px 0px rgba(0,0,0,0.50)',
        cursor: 'pointer',
        paddingTop: '10px',
        paddingBottom: '10px',
        position: 'relative',
    },
});

const useStyles = ({
    page: {
        //display: 'row',
        //backgroundColor: '#E4E4E4',
        margin: 10,
        padding: 10,
        flexGrow: 1,
    },
    body: {
        paddingTop: 15,
        paddingBottom: 40,
        paddingLeft: 15,
    },
    logo: {
        width: 60,
        height: 60,
    },
    logoFito: {
        width: 'auto',
        height: 'auto',
    },
    bussinessData: {
        width: 'auto',
        height: 'auto',
    },
    header:{
        marginTop:7,
        fontSize:10
    },
    secondHeader:{
        marginTop:7,
        fontSize:11,
        marginBottom:10
    },
    headerTable:{
        fontSize:10,
        marginVertical:'auto',
        padding:3,
        borderTop:'1pt solid rgba(0,0,0,0.31)',
        borderBottom:'1pt solid rgba(0,0,0,0.31)',
        borderLeft:'1pt solid rgba(0,0,0,0.31)',
    },
    lineTable:{
        fontSize:9,
        marginVertical:'auto',
        padding:3,
    },
    content: {
        marginRight:15,
        '@media min-width: 400': {
            flexDirection: 'row',
        },
    },
    content02: {
        marginRight:0,
        '@media min-width: 400': {
            flexDirection: 'row',
        },
    }
});

function GetIconM01 (icon:number) {
    switch(icon){
        case 1:
            return s1;
        case 2:
            return s2;
        case 3:
            return s3;
        case 4:
            return s4;
        case 5:
            return s5;
        case 6:
            return s6;
        case 7:
            return s7;
        case 8:
            return s8;
        default:
            return undefined;
    }
}

const PdfForm  = (props: ListChildComponentProps) =>{
    const register:IXForm = props.data[0];
    const me:IMe = props.data[1];
    const item:IPersonal = props.data[2];
    const firmante:IFirmante = props.data[3];

    const empresa:ICentroTrabajo|undefined = GetEmpresa(item.centrotrabajoId, me);

    

    return(
        <Document>
            <Page key={1} size="A4" style={PdfStyles.page} >                
                <View style={PdfStyles.rheader}> 
                    <View style={PdfStyles.rowlogo}>
                        <View style={PdfStyles.mainlogo}>
                            <Image style={PdfStyles.logo1} src={AppNealia_prevent_icon} />
                        </View>
                        <View style={PdfStyles.mainTitle}>
                            <Text>{`${register.title}`}</Text>                            
                            { empresa ?                                     
                                <Text>{empresa.description}</Text>                                    
                            :   null
                            }
                        </View>
                        <View style={PdfStyles.scdlogo}>
                            <Image style={PdfStyles.logo2} src={empresa!.logo} />
                        </View>
                    </View>                   
                    <View style={PdfStyles.separator}>                        
                    </View>
                </View>
                <View style={PdfStyles.apartado}>
                    <View style={PdfStyles.titlesec}>
                        <View style={PdfStyles.titletext}><Text><Text style={PdfStyles.numeration}> // </Text>{`CERTIFICADO DE TRABAJO - PERSONAL CUALIFICADO (según RD 614/2001)`}</Text></View>
                    </View>
                    <View style={PdfStyles.comentarios}>                        
                        <Text style={PdfStyles.ctext}>{`RD 614/2001, de de 8 de junio, sobre disposiciones mínimas para la protección de la salud y seguridad de los trabajadores frente el riesgo eléctrico. `}</Text>
                    </View>
                    <View style={PdfStyles.comentarios}>
                        <Text style={[PdfStyles.global, PdfStyles.ctextLegal, PdfStyles.remarkedInformation]}>{txt_legal_01}</Text>
                    </View>
                    <View style={PdfStyles.comentarios}>
                        <Text style={PdfStyles.ctext}>{`Sr/Sra ${register.usuario} como responsable de la empresa NOMBRE EMPRESA certifica que el trabajador D/Dña ${firmante.name} abajo firmante, ha sido autorizado para realizar determinados trabajos como trabajador cualificado con riesgo eléctrico, en base a su capacidad para hacerlos de forma correcta, en los términos establecidos en R.D. 614/2001, sobre disposiciones mínimas para la protección de la salud y seguridad de los trabajadores frente al riesgo eléctrico.`}</Text>
                    </View>
                    <View style={PdfStyles.comentarios}>
                        <Text style={PdfStyles.ctext}>{`Trabajos Autorizados `}</Text>
                    </View>
                    {txt_legal_01_points.map((point:string) => {
                        return (<View style={PdfStyles.comentarios}>
                                    <View style={PdfStyles.cspacer}>
                                        <View style={PdfStyles.square}></View>
                                    </View>
                                    <View style={PdfStyles.ctext}><Text>{point}</Text></View>
                                </View>
                        );
                    })}  
                    <View style={PdfStyles.comentarios}>
                        <Text style={PdfStyles.ctext}>{`Fecha: ${GetDatetimeFormat(register.dt_created)}`}</Text>
                    </View>
                    <View style={PdfStyles.signaturesWrapper}>
                    <View style={PdfStyles.signature}>
                        <Text style={PdfStyles.signatureAutor}>
                            {`Responsable:
                                \r\n${register.supervisor.name}
                                \r\n${register.supervisor.email}
                            `}
                        </Text>
                        {/* <Image
                            style={PdfStyles.signaturePanel}
                            src={register.supervisor.signature?register.supervisor.signature:''}
                        /> */}
                    </View>
                    <View style={PdfStyles.signature}>
                        <Text style={PdfStyles.signatureAutor}>
                            {`Firma personal:
                                \r\n${firmante.name}
                                \r\n${firmante.email}
                            `}
                        </Text>
                        {register.sign_mode == 2?
                            <Image
                                style={PdfStyles.signaturePanel}
                                src={firmante.signature || ''}
                            />
                        :
                            null
                        }
                    </View>
                </View>
                </View>
                {/* PDF Footer with page counter*/}
                {/* Footer con texto */}
                {/* <Text style={PdfStyles.footer} render={({ pageNumber, totalPages }) => `Página ${pageNumber} de ${totalPages} | Código: ${informe.code} - Autor: ${informe.author} Fecha de creación: ${formatDate(informe.dtCreated)} `} fixed /> */}
                <Text style={PdfStyles.footer} render={({ pageNumber, totalPages }) => `Página ${pageNumber} de ${totalPages} | ${register.title} - Fecha revisión: ${register.dt_reviewed} `} fixed />
                
                {/* Imagen en el footer */}
                <Image style={PdfStyles.footerImage2} src={AppNealia_prevent_icon} fixed />
                <Image style={PdfStyles.footerImage} src={AppNealia_prevent} fixed />
            </Page>
        </Document>
    );
}


const questions = [
    {id:"q_1", question: "Estado", answer: "", comment: "", a_template: [{id:"ADECUADO",name:"ADECUADO"},{id:"NO ADECUADO",name:"NO ADECUADO"},{id:"NO DISPONIBLE",name:"NO DISPONIBLE"}]},
    {id:"q_2", question: "Utilización", answer: "", comment: "", a_template: [{id:"CORRECTA",name:"CORRECTA"},{id:"INCORRECTA",name:"INCORRECTA"},{id:"NO UTILIZADO",name:"NO UTILIZADO"}]},
];

const txt_legal_01 = `Trabajador cualificado: trabajador autorizado que posee conocimientos especializados en materia de instalaciones eléctricas, debido a su formación acreditada, profesional o universitaria, o a su experiencia certificada de dos o más años.\r\n\
`;
const txt_legal_01_points = [
    'Ejecución de los trabajos sin tensión.',
    'Supresión y reposición de la baja y alta tensión.',
    'Reposición de fusibles en baja y alta tensión.',
    'Realización de tareas de vigilancia propias del jefe de trabajos en trabajos de alta tensión.',
    'Realización de trabajos en tensión – baja tensión.',
    'Maniobras, mediciones, ensayos y verificaciones en baja y alta tensión.',
    'Preparación y realización de trabajos en zonas de proximidad en baja y alta tensión.',
    'Vigilar a los trabajadores No Autorizados/ Ni cualificados para la realización de los trabajos en proximidad de alta y baja tensión.',
    'Trabajos en emplazamientos con atmosfera explosiva siguiendo un procedimiento previo específico.',
];

interface IFormProps {
    me:IMe;
    classes: any;
    handleCloseItemForm: Function;
    item:IPersonal;
    puestosTrabajo: any;
    trabajadores: any;
    signOptions: any;
    defaultSignId: number;
}

interface IFormState {
    creationModalOpen:boolean;
    creationSignatureOpen: boolean;
    creationSignaturePanel: boolean;
    modalAction:number;
    modalText:any;
    modalType:number;
    titleModal: string;
    htmlBody:boolean;
    loading:boolean;
    loadingModal:boolean;
    register:IXForm;
    m01modalopen:boolean;
    s_firmante?: IFirmante;
    s_firmante_role: boolean;
    bottomreached:boolean;
}

class Form extends Component<IFormProps, IFormState> {
    _going2upload:any = {attachments:[]};
    _form_ref:any = React.createRef();

    constructor(props: IFormProps) {
        super(props);
        this.state = {
            creationModalOpen:false,
            creationSignatureOpen: false,
            creationSignaturePanel: false,
            modalType: -1,
            modalAction: -1,
            modalText:'',
            htmlBody:false,
            loading: false,
            loadingModal: false,
            s_firmante_role: false,
            titleModal: `MENSAJE`,
            register: {
                filename: `Riesgo_electrico__Cualificacion_del_trabajador.pdf`,
                sign_mode: props.defaultSignId,
                doctype: 2,
                dt_reviewed: `18/06/18`,
                dt_created: new Date(),
                dt_edited: new Date(),
                title: `Riesgo eléctrico: Cualificacion del trabajador`,
                usuario: props.me.userIdName,
                m01: [],
                m02: [],
                puestoTrabajo: null,
                comment: "",
                firmantes: [
                    {
                        ...props.item,
                        name: `${props.item.name} ${props.item.surname}`
                    }
                    
                    // {
                    //     id: 1,
                    //     name_surname: props.me.userIdName,
                    // },
                    // {
                    //     id: 2,
                    //     name_surname: `${props.item.name} ${props.item.surname}`
                    // }
                ],
                supervisor: {
                    id: `0000000`,
                    name: props.me.userIdName,
                    email: props.me.userId,
                    phone: ''
                },
            },
            m01modalopen: false,
            bottomreached: false
        };
    }

    componentDidMount(){
        this.addlisteners();
    }

    CheckBottomReached=()=>{
        return (this._form_ref.current.scrollHeight - this._form_ref.current.scrollTop - this._form_ref.current.clientHeight < 10);
    }

    addlisteners=()=>{
        setTimeout(() => {
            if(this._form_ref.current == null) {
                this.addlisteners();
            } else {
                const bottomReached = this.CheckBottomReached();
                if(bottomReached)
                    this.setState({bottomreached: true});
                this._form_ref.current.addEventListener('scroll', this.isscrolling);
                this._form_ref.current.addEventListener('resize', this.isscrolling);
            }
        }, 150);
    }

    isscrolling=()=>{
        const bottomReached = this.CheckBottomReached();
        this.setState({bottomreached: bottomReached});
    }

    onCloseCreationModal = () => {
        this.setState({ creationModalOpen: false, modalText: undefined, htmlBody:false });
    }

    onCloseFormAddM01 = () => {
        this.setState({ m01modalopen: false });
    }

    onCloseSignatureModal = () => {
        this.setState({ creationSignatureOpen: false });
    }

    onCloseSignaturePanel = () => {
        this.setState({ creationSignaturePanel: false });
    }

    /* CALL BACKEND */

    sendAttachFiles = async () => {
        let response = await OrderService.createImgBlob(this._going2upload, 8);
        this._going2upload = {attachments:[]};
        let p = `Fallo en el proceso, vuelva a intentarlo.`;
        let modaltype = 1;
        let modalaction = -1;

        if(response == 1) {
            p = `Formulario adjuntado correctamente`;
            modaltype = 4;
            modalaction= 1;
        }
        
        this.setState({
            creationModalOpen:true,
            modalText:p,
            modalType: modaltype,
            modalAction: modalaction,
            loadingModal: false
        });
    }

    /* Callbacks component SignaturePanel */

    handleFirmanteDataChange = (index:number, id:string, firmante: IFirmante) => {
        const {register} = this.state;

        let nregister = cloneDeep(register);
        
        nregister.firmantes[index][id] = firmante[id];

        this.setState({register: nregister});
    }

    handleAddSign = (index: number, firmante: IFirmante) => {
        const {register} = this.state;

        let nregister = cloneDeep(register);
        
        nregister.firmantes[index].signature = firmante.signature;

        this.setState({register: nregister});
    }

    handleDeleteSign = (index:number, firmante:IFirmante) => {
        const {register} = this.state;

        let nregister = cloneDeep(register);
        
        nregister.firmantes[index].signature = undefined;

        this.setState({register: nregister});
    }

    handleAskCreateForm=()=>{
        const {item} = this.props;
        this.setState({
            modalAction: 2,
            //modalText: `¡ATENCIÓN! ¿Quiere finalizar el formulario y adjuntarlo al trabajador ${item.id}?`,
            modalText: `¡ATENCIÓN! ¿Quiere finalizar el formulario y adjuntarlo?`,
            modalType: 2,
            creationModalOpen: true,
        });
    }

    //** End callbacks component SignaturePanel */

    handleCreateModal = (typeAction: number) => {
        switch(typeAction){
            case 1: // Implica que se ha adjuntado corractamente el formulario
                this.props.handleCloseItemForm(true);
                this.onCloseCreationModal();
                break;
            case 2: // Implica iniciar el proceso de adjuntar el formulario
                this.setState({loadingModal: true},()=>{
                    this.handleCreateForm();
                });
                break;
            default:
                this.onCloseCreationModal();
        }
    }

    handleChangeSelectDevices=(e:any, list:Array<string>)=>{
        let nRegister = cloneDeep(this.state.register);
        nRegister.puestoTrabajo = list;
        this.setState({ register: nRegister });
    }

    
    handleChangeUser=(id:string, e:any)=>{
        let nRegister = cloneDeep(this.state.register);
        nRegister[id] = e.target.value;
        this.setState({ register: nRegister });
    }

    handleChangeFirmantes=(e:any, list:Array<any>)=>{
        let nRegister = cloneDeep(this.state.register);
        nRegister.firmantes = list;
        this.setState({ register: nRegister });
    }

    handleCreateForm=()=>{

        const {item, me} = this.props;
        const {register} = this.state;

        let promises:any = [];

        let t_register = cloneDeep(register);
        t_register.firmantes = [];

        register.firmantes.map((firmante:IFirmante, idx:number)=>{
            let dale = 
                <PdfForm index={idx} data={[register, me, item, firmante]} style={{}} />

            const blob = pdf(dale).toBlob();

            promises.push(new Promise((resolve, reject)=>{
                blob.then(ret=>{

                    let reader = new FileReader();

                    reader.onloadend = async ()=>{
                        this._going2upload.attachments.push({name: register.filename, sign_mode: register.sign_mode, firmante: firmante, doctype: register.doctype, stream: reader.result, centrotrabajoId: item.centrotrabajoId, tipo: 3, listadoId: 1, id: firmante.id, metadata: t_register});
                        //this.setState({loading:true }, ()=>this.sendAttachFiles());
                        //this.sendAttachFiles();
                        resolve(1);
                    }
                    reader.readAsDataURL(ret);

                }).catch(err=>{
                    console.log(err)
                    reject(0)
                })
            }));
        })

        

        Promise.all(promises)
            .then(results => {
                //console.log(this._going2upload.attachments);
                this.sendAttachFiles();
            })

        // blob.then(ret=>{

        //     let reader = new FileReader();

        //     reader.onloadend = async ()=>{
        //         this._going2upload.attachments.push({name: `Informe_EPIs_03_personal.pdf`, stream: reader.result, centrotrabajoId: item.centrotrabajoId, tipo: 3, listadoId: 1, id: item.id, metadata: register});
        //         //this.setState({loading:true }, ()=>this.sendAttachFiles());
        //         this.sendAttachFiles();
        //     }
        //     reader.readAsDataURL(ret);

        // }).catch(err=>{
        //     console.log(err)
        // })
    }

    verifyForm=(register:IXForm)=>{
        // Verifica que por lo menos hay un firmante
        if(register.firmantes.length == 0)
            return false;

        if(!this.state.bottomreached)
            return false;

        return true;
    }

    render() {
        const {register, loading, creationSignaturePanel, s_firmante} = this.state;
        const {me, puestosTrabajo, trabajadores, signOptions} = this.props;

        /*let usersinspected = register.inspections.map((inspection:IIQuestion, index:number)=>{
            if(inspection.used)
                return(this.renderUser(inspection, index, secciones));
        });*/

        return (
            <Dialog style={{padding:'25px'}} fullScreen={false} open={true} aria-labelledby="form-dialog-title" className='dialog-data'>
                <DialogTitle id="form-dialog-title">
                    <div className="App-header">
                        {`${register.title}`}
                    </div>
                </DialogTitle>
                <DialogContent ref={this._form_ref}>
                    <div>

                    <div style={{marginBottom: 20}}>
                        <SelectorField
                            key={0}
                            name={'Método firma'}
                            id={'sign_mode'}
                            deviceData={register}
                            fieldsData={signOptions}
                            handleFieldChange={this.handleChangeUser}
                            required={true}
                            style={1}
                            className='filter-item'
                        />
                    </div>

                    <div  style={{width:'100%', marginTop:10, marginBottom:30}}>
                        <List id={"firmantes"} data={register.firmantes} multiple={true} name={'Trabajador'} placeholder={'Firmantes...'} options={trabajadores} handleChangeSelect={(e:any, list:any)=>this.handleChangeFirmantes(e,list)}/>
                    </div>
                    <div>
                        <div className="Form-section">
                            {`Información para el trabajador`}
                        </div>
                        <p style={{whiteSpace:'pre-line', textAlign:'justify'}}>
                            {txt_legal_01}
                        </p>
                        <p>Trabajos autorizados:</p>
                        <ul>
                            {txt_legal_01_points.map((point: string) => {
                                return (
                                <li >                                        
                                    { point }
                                </li>
                                );
                            })}
                        </ul>
                    </div>
                    <div>
                        {this.state.creationModalOpen?
                            <CreateModal
                                onCloseCreationModal={this.onCloseCreationModal}
                                isOpen={this.state.creationModalOpen}
                                inputText={this.state.modalText}
                                typeModal={this.state.modalType}
                                action={this.handleCreateModal}
                                typeAction={this.state.modalAction}
                                loadingModal={this.state.loadingModal}
                                titleModal={this.state.titleModal}
                            />
                        :
                            null
                        }

                        {creationSignaturePanel?
                            <SignaturePanel
                            handleCloseItemForm={this.onCloseSignaturePanel}
                            handleAddSign={this.handleAddSign}
                            handleDeleteSign={this.handleDeleteSign}
                            handleFirmanteDataChange={this.handleFirmanteDataChange}
                            firmantes={register.firmantes}
                            sign_mode={register.sign_mode}
                            handleAskCreateForm={this.handleAskCreateForm}
                            />
                        :
                            null
                        }
                    </div>
                    </div>
                </DialogContent>
                <DialogActions style={{ borderTop: '#ff972b solid 3px'}}>
                    {!loading?
                        <div style={{marginRight:'auto', display:'flex', marginLeft: 'auto', width: '90%', justifyContent:'space-between'}}>
                            <Button onClick={()=>this.props.handleCloseItemForm(false)} className="cancel-dialog">
                                {'Cancelar'}
                            </Button>
                            <Button disabled={!this.verifyForm(register)} onClick={()=>this.setState({creationSignaturePanel: true})} className={`confirm-dialog ${!this.verifyForm(register)?`confirm-dialog-disable`:``}`}>
                                {`Firmar`}
                            </Button>
                        </div>
                    :
                        <Loader loading={true}/>
                    }
                </DialogActions>
            </Dialog>
        );
    }
}

export default withStyles(styles)(Form);