import { Grid, TextField, makeStyles } from "@material-ui/core";
import { FormFieldsProps } from "./types";
import classnames from "classnames";
import { DatePicker } from "@material-ui/pickers";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import React from "react";
import { InformeRouteType } from "../../../../hooks/useInformesRouteParams";

export type Props = FormFieldsProps;

export const useStyles = makeStyles(() => ({
  root: {},
  field: {
    backgroundColor: "white",
    borderRadius: 11,
    marginTop: 15,
  },
}));

const InformeCreateFormFields = (props: Props) => {
  const {
    values,
    errors,
    touched,
    component,
    handleChange,
    handleBlur,
    onDateChange,
    disabled,
    disabledFields,
    isSubmitting,
    className,
  } = props;

  const classes = useStyles();

  const Component = component || "div";

  const formatDate = (date: MaterialUiPickersDate) => date?.toISOString() ?? null;

  return (
    <Component className={classnames(className)}>
      <Grid container spacing={2}>
        <Grid item sm={9} xs={12}>
          <TextField
            name="name"
            label="Nombre"
            type="text"
            size="medium"
            placeholder="Nombre Informe"
            value={values.name}
            onChange={handleChange}
            onBlur={handleBlur}
            InputLabelProps={{ shrink: true }}
            disabled={disabled || disabledFields?.name || isSubmitting}
            error={Boolean(errors.name && touched.name)}
            helperText={errors.name && touched.name ? errors.name : null}
            fullWidth
            className={classnames("dialog-item-25", classes.field)}
            variant="outlined"
          />
        </Grid>

        <Grid item sm={3} xs={12}>
          <DatePicker
            name="dtStartVisit"
            label="Fecha Inicio"
            value={values.dtStartVisit ?? null}
            format="dd/MM/yyyy"
            inputVariant="outlined"
            InputLabelProps={{ shrink: true }}
            onChange={(date) => onDateChange("dtStartVisit", formatDate(date))}
            disabled={disabled || disabledFields?.dtStartVisit || isSubmitting}
            error={Boolean(errors.dtStartVisit && touched.dtStartVisit)}
            helperText={
              errors.dtStartVisit && touched.dtStartVisit
                ? errors.dtStartVisit
                : null
            }
            className={classnames("dialog-item-25", classes.field)}
            fullWidth
            animateYearScrolling
            clearable
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            name="description"
            label="Descripción"
            type="text"
            size="medium"
            placeholder="Detalles de la visita"
            value={values.description}
            onChange={handleChange}
            onBlur={handleBlur}
            InputLabelProps={{ shrink: true }}
            disabled={disabled || disabledFields?.description || isSubmitting}
            error={Boolean(errors.description && touched.description)}
            helperText={
              errors.description && touched.description
                ? errors.description
                : null
            }
            fullWidth
            className={classnames("dialog-item-25", classes.field)}
            variant="outlined"
            multiline
            minRows={3}
            inputProps={{ maxLength: 10000 }}
          />
        </Grid>
        
        {values.type == InformeRouteType.AUDITORIAS?
          <React.Fragment>
            <Grid item xs={12}>
              <TextField
                name="team"
                label="Equipo Auditor"
                type="text"
                size="medium"
                placeholder="Menciona al equipo auditor..."
                value={values.team}
                onChange={handleChange}
                onBlur={handleBlur}
                InputLabelProps={{ shrink: true }}
                disabled={disabled || disabledFields?.team || isSubmitting}
                error={Boolean(errors.team && touched.team)}
                helperText={
                  errors.team && touched.team
                    ? errors.team
                    : null
                }
                fullWidth
                className={classnames("dialog-item-25", classes.field)}
                variant="outlined"
                multiline
                minRows={3}
                inputProps={{ maxLength: 3000 }}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                name="participant"
                label="Participantes"
                type="text"
                size="medium"
                placeholder="Personas que participan"
                value={values.participant}
                onChange={handleChange}
                onBlur={handleBlur}
                InputLabelProps={{ shrink: true }}
                disabled={disabled || disabledFields?.team || isSubmitting}
                error={Boolean(errors.participant && touched.participant)}
                helperText={
                  errors.participant && touched.participant
                    ? errors.participant
                    : null
                }
                fullWidth
                className={classnames("dialog-item-25", classes.field)}
                variant="outlined"
                multiline
                minRows={3}
                inputProps={{ maxLength: 3000 }}
              />
            </Grid>
          </React.Fragment>
        :
          null
        }      
      </Grid>
    </Component>
  );
};

export default InformeCreateFormFields;
