import React, { Component } from 'react';
import './App.css';
import { Redirect, Route, Switch } from "react-router-dom";
import { IFilter, IMe } from './types';
import Home from './Components/Home';
import Listado from './Components/Listado';
import PanelControl from './Components/ControlPanel';
import PersonalPanel from './Components/PersonalControlPanel';
import PersonalDoc from './Components/PersonalDoc';
import ListadoDetail from './Components/ListadoDetail';
import News from './Components/News';
import ListadoUsuarios from './Components/ListadoUsuarios';
import Informes from './modules/Informes/containers/InformesRoute'
import Informe from './modules/Informes/containers/InformeOverviewRoute';
import Facturacion from './Components/Facturacion';

import SettingUsers from './Components/SettingUsers';
import SettingSections from './Components/SettingSection';
import SettingJobPlaces from './Components/SettingJobPlace';

import OutOfService from './Components/OutOfService';
import Unauthorized from './Components/Unauthorized';
import OrderService from './ApiClients/OrderService';
import { cloneDeep } from 'lodash';
import appData from './ApiClients/appData';
import './Components/Home.css';
import './Components/ListadoDetail.css';
import './Components/Forms/Forms.css';
import './Components/Filter.css';
import './Components/SettingJobPlace.css';

import Dashboard from './Components/Dashboard';
import Calendar from './Components/Calendar';

const version:string = 'v4.3.1'; // 13-02-2025

const i_filters:IFilter = {centrotrabajoId: -1, f_mp: {}, f_mc: {}, f_personal: {}, f_personal_doc: {}, f_cp: {}, f_ss: {}, f_sj: {}, f_ul:{}, f_fa:{}};

interface IAppProps {
  history: any;
  match:any;
}

interface IAppState {
  myInfo?: IMe;
  filters: IFilter;
  ninformed: boolean;
}

class App extends Component<IAppProps, IAppState> {
  constructor(props: IAppProps) {
    super(props);
    this.state = {
      myInfo: undefined,
      filters: i_filters,
      ninformed: false
    };
  }

  componentWillMount(){
    this.getMyInfo();
  }

  componentDidMount(){
  }

  getMyInfo= async ()=>{
    let response = await OrderService.me();
    this.ReviewMe(response);
  }

  ReviewMe=(me:IMe)=>{
    if(!me){
      this.metrics(3);
      this.props.history.push(`/fueraServicio`);
    } else if(!me.authorized) {
      this.metrics(2);
      this.props.history.push(`/noAutorizado`);
    } else {
      this.metrics(1);
      this.setState({myInfo: me});
    } 
  }

  metrics = async (metricType:number) => {
    await OrderService.metricService(metricType);
  }

  handleCheckFilter = (centrotrabajoId: number) => {
    if(this.state.filters.centrotrabajoId == centrotrabajoId)
      return;

    let n_filters = i_filters;
    n_filters.centrotrabajoId = centrotrabajoId;
    this.setState({filters: n_filters});
  }

  handleChangeFilter = (id:string, filters:any) => {
    let f = cloneDeep(this.state.filters);
    f[id] = filters;
    this.setState({filters: f});
  }

  updateMe = (me:IMe, action:number)=>{
    this.setState({myInfo: me}, ()=>OrderService.saveData(me, action));
  }

  updateNinformed = ()=>{
    this.setState({ninformed: true});
  }

  render() {
    return (
        <Switch>
          <Route
          exact
          path="/"
          render={(props) =>
            <Home
              {...props}
              myInfo={this.state.myInfo}
              version={version}
              updateNinformed={this.updateNinformed}
              ninformed={this.state.ninformed}
            />
          }
        />
        <Route
          exact
          path="/news"
          render={(props) =>
            <News
              {...props}
              myInfo={this.state.myInfo}
              updateMe={this.updateMe}
              version={version}
          />
          }
        />
        <Route
          exact
          path="/:centrotrabajoId/dashboard"
          render={(props) =>
            <Dashboard
              {...props}
              me={this.state.myInfo}              
              version={version}
              history={this.props.history}
          />
          }
        />
        <Route
          exact
          path="/:centrotrabajoId/listado/:tipo"
          render={(props) =>
            <Listado
              {...props}
              me={this.state.myInfo}
              version={version}
              filters={this.state.filters}
              handleChangeFilter={this.handleChangeFilter}
              handleCheckFilter={this.handleCheckFilter}
          />
          }
        />
        <Route
          exact
          path="/:centrotrabajoId/panelcontrol/:tipo"
          render={(props) =>
            <PanelControl
              {...props}
              me={this.state.myInfo}
              version={version}
              filters={this.state.filters}
              handleChangeFilter={this.handleChangeFilter}
              handleCheckFilter={this.handleCheckFilter}
          />
          }
        />
        <Route
          exact
          path="/:centrotrabajoId/listado/:tipo/:id"
          render={(props) =>
            <ListadoDetail
              {...props}
              me={this.state.myInfo}
              version={version}
          />
          }
        />
        <Route
          exact
          path="/:centrotrabajoId/personal"
          render={(props) =>
            <PersonalPanel
              {...props}
              me={this.state.myInfo}
              version={version}
              filters={this.state.filters}
              handleChangeFilter={this.handleChangeFilter}
              handleCheckFilter={this.handleCheckFilter}
          />
          }
        />
        <Route
          exact
          path="/:centrotrabajoId/personal/documents"
          render={(props) =>
            <PersonalDoc
              {...props}
              me={this.state.myInfo}
              version={version}
              filters={this.state.filters}
              handleChangeFilter={this.handleChangeFilter}
              handleCheckFilter={this.handleCheckFilter}

          />
          }
        />
        <Route
          exact
          path={`/:centrotrabajoId/reports/:informeRouteType`}
          render={(props) => (
            <Informes
              {...props}
              me={this.state.myInfo}
              version={version}
              filters={this.state.filters}
              handleChangeFilter={this.handleChangeFilter}
              handleCheckFilter={this.handleCheckFilter}
            />
          )}
        />
        <Route
          exact
          path={`/:centrotrabajoId/reports/:informeRouteType/:informeId`}
          render={(props) => (
            <Informe
              {...props}
              me={this.state.myInfo}
              version={version}
            />
          )}
        />
        <Route
          exact
          path="/:centrotrabajoId/setting/users"
          render={(props) =>
            <SettingUsers
              {...props}
              me={this.state.myInfo}
              version={version}
          />
          }
        />
        <Route
          exact
          path="/:centrotrabajoId/setting/sections"
          render={(props) =>
            <SettingSections
              {...props}
              me={this.state.myInfo}
              version={version}
              filters={this.state.filters}
              handleChangeFilter={this.handleChangeFilter}
              handleCheckFilter={this.handleCheckFilter}
          />
          }
        />
        <Route
          exact
          path="/:centrotrabajoId/setting/jobplaces"
          render={(props) =>
            <SettingJobPlaces
              {...props}
              me={this.state.myInfo}
              version={version}
              filters={this.state.filters}
              handleChangeFilter={this.handleChangeFilter}
              handleCheckFilter={this.handleCheckFilter}
          />
          }
        />
        <Route
          exact
          path="/noAutorizado"
          render={(props) =>
            <Unauthorized
              {...props}
              refreshMyInfo={this.getMyInfo}
              myInfo={this.state.myInfo}
            />
          }
        />
        <Route
          exact
          path="/fueraServicio"
          render={(props) =>
            <OutOfService
              {...props}
              refreshMyInfo={this.getMyInfo}
              myInfo={this.state.myInfo}
            />
          }
        />
        <Route 
        exact
        path="/userlist"
        render={(props)=>
            <ListadoUsuarios
              {...props}              
              me={this.state.myInfo}
              version={version}
              filters={this.state.filters}
              handleChangeFilter={this.handleChangeFilter}
              handleCheckFilter={this.handleCheckFilter}
            />
          }
        />        
        <Route 
        exact
        path="/:centrotrabajoId/agenda"
        render={(props)=>
            <Calendar
              {...props}
              me={this.state.myInfo}              
              version={version}
              history={this.props.history}
            />
          }
        />
        <Route 
        exact
        path="/facturacion"
        render={(props)=>
            <Facturacion
              {...props}              
              me={this.state.myInfo}
              version={version}
              filters={this.state.filters}
              handleChangeFilter={this.handleChangeFilter}
              handleCheckFilter={this.handleCheckFilter}
            />
          }
        />  
        <Redirect from="/" to="/" />
      </Switch>
    );
  }
}

export default App;
