import React, { Component } from 'react';
import { createStyles, withStyles, Theme } from '@material-ui/core/styles';
import { Text, View, StyleSheet, Image, PDFViewer, PDFDownloadLink, pdf } from '@react-pdf/renderer';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/AddBoxOutlined';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import ViewIcon from '@material-ui/icons/VisibilityOutlined';
import SignIcon from '@material-ui/icons/EditOutlined';
import DatePicker from '../DatePicker';
import { IMe, IPersonal, IXForm, ICentroTrabajo, VIIIQuestion, IQuestion, IFirmante } from '../../types';
import { SelectorField, CheckBoxField, TextFieldInput } from '../FormsWidgets';
import { GetRole, GetEmpresa } from '../../helpers';
import TakeIcon from '@material-ui/icons/CheckCircleOutline';
import { cloneDeep, filter, find, findIndex } from 'lodash';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AddActionIcon from '@material-ui/icons/AddBox';
import IconButton from '@material-ui/core/IconButton';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import Paper from '@material-ui/core/Paper';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import PageViewIcon from '@material-ui/icons/Pageview';
import { GetConcatedDate, GetDatetimeFormat } from '../../vc_general';
import OrderService from '../../ApiClients/OrderService';
import LoaderBeauty from '../Material/activityProgress';
import CreateModal from '../CreateDialog';
import List from '../Material/MultiSelect';
import Loader from '../Loader';
import { ListChildComponentProps } from 'react-window';
import s1 from '../../Assets/widgets/s1.png';
import s2 from '../../Assets/widgets/s2.png';
import s3 from '../../Assets/widgets/s3.png';
import s4 from '../../Assets/widgets/s4.png';
import s5 from '../../Assets/widgets/s5.png';
import s6 from '../../Assets/widgets/s6.png';
import s7 from '../../Assets/widgets/s7.png';
import s8 from '../../Assets/widgets/s8.png';
import { Delete } from '@material-ui/icons';
import { PdfStyles } from './pdfStyles';
import AppNealia_prevent_icon from '../../Assets/nealia_prevent_icon.png';
import AppNealia_prevent from '../../Assets/nealia_prevent.png';
import { Document, Page } from '../Material/pdfRenderComponents';
import SignaturePanel from '../Material/SignatureControl';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

const m01styles = ({
    button: {
        margin: 1,
        marginRight: 15
    },
    leftIcon: {
        marginRight: 10,
    },
    rightIcon: {
        marginLeft: 1,
    },
    iconSmall: {
        fontSize: 20,
    },
    formControl: {
        margin: '1px',
        minWidth: 120,
        marginRight: 'auto',
    },
});

const styles = (theme: Theme) => createStyles({
    container: {
        padding: '10px',
        textAlign: 'center',
        overflowY: 'auto'
    },
    image: {
        width: '100%',
        height: 'auto'
    },
    promotion: {
        //backgroundColor: '#f00',
        padding: '3px 15px',
        //color: '#fff',
        verticalAlign: 'middle',
    },
    root: {
        width: '100%',
    },
    paper: {
        width: '100%',
        marginTop: '5px',
        overflowX: 'auto',
        borderWidth: 2,
        borderColor: '#00809b',
        borderStyle: 'solid',
    },
    table: {
        //tableLayout:'fixed'
        width: '100%',
    },
    row: {
        //wordWrap:'break-word'
        width: '100%',
        //backgroundColor:'#ddd'
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        // fontWeight: theme.typography.fontWeightRegular,
    },
    item: {
        maxWidth: '200px',
        border: '2px solid #ccc',
        borderRadius: '15px',
        margin: '5px 10px',
        width: 'calc(100% - 20px)!important',
        boxShadow: '5px 5px 5px 0px rgba(0,0,0,0.50)',
        cursor: 'pointer',
        paddingTop: '10px',
        paddingBottom: '10px',
        position: 'relative',
    },
});

const useStyles = ({
    page: {
        //display: 'row',
        //backgroundColor: '#E4E4E4',
        margin: 10,
        padding: 10,
        flexGrow: 1,
    },
    body: {
        paddingTop: 15,
        paddingBottom: 40,
        paddingLeft: 15,
    },
    logo: {
        width: 60,
        height: 60,
    },
    logoFito: {
        width: 'auto',
        height: 'auto',
    },
    bussinessData: {
        width: 'auto',
        height: 'auto',
    },
    header: {
        marginTop: 7,
        fontSize: 10
    },
    secondHeader: {
        marginTop: 7,
        fontSize: 11,
        marginBottom: 10
    },
    headerTable: {
        fontSize: 10,
        marginVertical: 'auto',
        padding: 3,
        borderTop: '1pt solid rgba(0,0,0,0.31)',
        borderBottom: '1pt solid rgba(0,0,0,0.31)',
        borderLeft: '1pt solid rgba(0,0,0,0.31)',
    },
    lineTable: {
        fontSize: 9,
        marginVertical: 'auto',
        padding: 3,
    },
    content: {
        marginRight: 15,
        '@media min-width: 400': {
            flexDirection: 'row',
        },
    },
    content02: {
        marginRight: 0,
        '@media min-width: 400': {
            flexDirection: 'row',
        },
    }
});

const PdfForm = (props: ListChildComponentProps) => {
    const register: IXForm = props.data[0];
    const me: IMe = props.data[1];
    const item: IPersonal = props.data[2];
    const firmante: IFirmante = props.data[3];
    console.log(register);
    
    const empresa: ICentroTrabajo | undefined = GetEmpresa(item.centrotrabajoId, me);

    return (
        <Document>
            <Page key={1} size="A4" style={PdfStyles.page} >
                <View style={PdfStyles.rheader}>
                    <View style={PdfStyles.rowlogo}>
                        <View style={PdfStyles.mainlogo}>
                            <Image style={PdfStyles.logo1} src={AppNealia_prevent_icon} />
                        </View>
                        <View style={PdfStyles.mainTitle}>
                            <Text>{`${register.title}`}</Text>
                            {empresa ?
                                <Text>{empresa.description}</Text>
                                : null
                            }
                        </View>
                        <View style={PdfStyles.scdlogo}>
                            <Image style={PdfStyles.logo2} src={empresa!.logo} />
                        </View>
                    </View>
                    <View style={PdfStyles.separator}>
                    </View>
                </View>
                <View style={PdfStyles.apartado}>
                    <View style={PdfStyles.titlesec}>
                        <View style={PdfStyles.titletext}><Text><Text style={PdfStyles.numeration}> // </Text>{`Vigilancia de la salud\r\nConsentimiento informado de pruebas médicas específicas`}</Text></View>
                    </View>
                    <View style={PdfStyles.comentarios}>
                        <Text style={[PdfStyles.ctext, PdfStyles.ctextBold, PdfStyles.ctextMayus]}>{`PRUEBAS MÉDICAS: ANÁLISIS EN SANGRE DE MARCADORES TUMORALES`}</Text>
                    </View>
                    <View style={PdfStyles.comentarios}>
                        <Text style={[PdfStyles.ctext, PdfStyles.ctextBold]}>{`¿Qué son los marcadores tumorales?`}</Text>
                    </View>
                    <View style={PdfStyles.comentarios}>
                        <Text style={PdfStyles.ctext}>{`Los marcadores tumorales son sustancias producidas por el organismo como respuesta a una situación benigna o como respuesta a una alteración, un cáncer u otro tipo de patología.\r\n
Es importante que sepa que la detección de estos marcadores tumorales siempre tiene que ir acompañada de una visita a su médico especialista, para que pueda efectuarle una anamnesis clínica enfocada a detectar aquella clínica y factores de riesgo existentes, una buena exploración médica y, cuando así esté indicado por los protocolos médicos, la realización de ciertas pruebas complementarias.\r\n 
Nunca, una sola detección analítica de marcadores tumorales es indicativa de nada por sí sola, ni de existencia de patología, ni de normalidad.\r\n 
Se usan en la clínica médica habitual como una prueba de cribaje precoz de alteraciones. Algunos están asociados con un solo tipo de cáncer, mientras que otros están asociados con dos o más tipos de cáncer. No se ha encontrado un marcador de tumores "universal" que pueda detectar cualquier tipo de cáncer.\r\n
Tal y como ya se ha indicado, existen algunas limitaciones en el uso de marcadores tumorales. Algunas veces, situaciones benignas pueden ir acompañadas de un aumento de las concentraciones de algunos marcadores, sin que tenga una evidencia patológica posterior. Y en otras ocasiones, valores normales de estos marcadores van acompañados de patología, o cáncer, ya que no se han identificado los marcadores para todos los tipos de cáncer.\r\n
La empresa, conjuntamente con el Servicio de Prevención Ajeno, está trabajando en una propuesta de mejora para la salud colectiva de los/las trabajadores/as, ofreciendo una detección de los marcadores tumorales más frecuentes, con un seguimiento periódico a largo plazo. Con la información obtenida, recomendamos encarecidamente el seguimiento posterior con sus especialistas para la valoración de cada caso particular.\r\n
En ningún caso, los datos obtenidos serán facilitados a la empresa, ni serán tenidos en cuenta para la valoración de la aptitud del trabajador en su puesto de trabajo.\r\n
Se iniciará el estudio con la detección de:\r\n\r\n`}</Text>
                    </View>
                    <View style={PdfStyles.comentarios}>
                        <Text><Text style={[PdfStyles.ctext, PdfStyles.ctextBold]}>{`CA19-9`}</Text><Text style={[PdfStyles.ctext]}>{` - Realización con periodicidad BIANUAL`}</Text></Text>
                    </View>
                    {txt_legal_01_points.map((point: string) => {
                        return (<View style={PdfStyles.comentarios}>
                            <View style={PdfStyles.cspacer}>
                                <View style={PdfStyles.square}></View>
                            </View>
                            <View style={PdfStyles.ctext}><Text>{point}</Text></View>
                        </View>
                        );
                    })}
                    <View style={PdfStyles.comentarios}>
                        <Text>{`\r\n`}
                            <Text style={PdfStyles.ctext}>
                                {`Las citadas pruebas se realizarán única y exclusivamente en el supuesto que el/la trabajador/a otorgue su consentimiento específico y libre y haya sido informado/a previamente, quedando expresamente prohibido que los datos recabados sean usados con fines discriminatorios ni en perjuicio de el/la trabajador/a. `}
                            </Text>
                            <Text style={[PdfStyles.ctext, PdfStyles.ctextBold]}>{`Solamente el/la trabajador/a recibirá los resultados de la/s prueba/s realizadas.\r\n`}</Text>
                            <Text style={PdfStyles.ctext}>
                                {`Con la firma de este documento hago constar que he sido informado de manera totalmente comprensible para mí, respecto a las pruebas a realizar.\r\n\r\n`}
                            </Text>
                        </Text>
                    </View>
                    
                    <View style={[PdfStyles.table, {width:'100%'}]}>
                        <View style={PdfStyles.tableRow}>
                            <View style={[PdfStyles.tableCol, PdfStyles.tableColWrapperHeader]}>
                                <Text style={PdfStyles.tableCell}>Empresa</Text>
                            </View>
                            <View style={[PdfStyles.tableCol, PdfStyles.tableColLineRight]}>
                                <Text style={PdfStyles.tableCell}>{`${empresa?.description}`}</Text>
                            </View>
                        </View>
                        <View style={PdfStyles.tableRow}>
                            <View style={[PdfStyles.tableCol, PdfStyles.tableColWrapperHeader]}>
                                <Text style={PdfStyles.tableCell}>Apellidos</Text>
                            </View>
                            <View style={[PdfStyles.tableCol, PdfStyles.tableColLineRight]}>
                                <Text style={PdfStyles.tableCell}>{`${firmante.surname}`}</Text>
                            </View>
                        </View>
                        <View style={PdfStyles.tableRow}>
                            <View style={[PdfStyles.tableCol, PdfStyles.tableColWrapperHeader]}>
                                <Text style={PdfStyles.tableCell}>Nombre</Text>
                            </View>
                            <View style={[PdfStyles.tableCol, PdfStyles.tableColLineRight]}>
                                <Text style={PdfStyles.tableCell}>{`${firmante.onlyName}`}</Text>
                            </View>
                        </View>
                        <View style={PdfStyles.tableRow}>
                            <View style={[PdfStyles.tableCol, PdfStyles.tableColWrapperHeader]}>
                                <Text style={PdfStyles.tableCell}>DNI/NIE</Text>
                            </View>
                            <View style={[PdfStyles.tableCol, PdfStyles.tableColLineRight]}>
                                <Text style={PdfStyles.tableCell}>{`${firmante.ssn}`}</Text>
                            </View>
                        </View>
                        <View style={PdfStyles.tableRow}>
                            <View style={[PdfStyles.tableCol, PdfStyles.tableColWrapperHeader]}>
                                <Text style={PdfStyles.tableCell}>Fecha nacimiento</Text>
                            </View>
                            <View style={[PdfStyles.tableCol, PdfStyles.tableColLineRight]}>
                                <Text style={PdfStyles.tableCell}>{`${firmante.birhday}`}</Text>
                            </View>
                        </View>
                        <View style={PdfStyles.tableRow}>
                            <View style={[PdfStyles.tableCol, PdfStyles.tableColWrapperHeader]}>
                                <Text style={PdfStyles.tableCell}>Sexo</Text>
                            </View>
                            <View style={[PdfStyles.tableCol, PdfStyles.tableColLineRight]}>
                                <Text style={PdfStyles.tableCell}>{`${firmante.gender}`}</Text>
                            </View>
                        </View>
                        <View style={PdfStyles.tableRow}>
                            <View style={[PdfStyles.tableCol, PdfStyles.tableColWrapperHeader]}>
                                <Text style={PdfStyles.tableCell}>E-mail</Text>
                            </View>
                            <View style={[PdfStyles.tableCol, PdfStyles.tableColLineRight]}>
                                <Text style={PdfStyles.tableCell}>{`${firmante.email}`}</Text>
                            </View>
                        </View>
                        <View style={PdfStyles.tableRow}>
                            <View style={[PdfStyles.tableCol, PdfStyles.tableColWrapperHeader]}>
                                <Text style={PdfStyles.tableCell}>Teléfono móvil</Text>
                            </View>
                            <View style={[PdfStyles.tableCol, PdfStyles.tableColLineRight]}>
                                <Text style={PdfStyles.tableCell}>{`${firmante.phone}`}</Text>
                            </View>
                        </View>
                        <View style={PdfStyles.tableRow}>
                            <View style={[PdfStyles.tableCol, PdfStyles.tableColLineRight, PdfStyles.tableColLast, {width:'100%'}]}>
                                <View style={[PdfStyles.tableCell, PdfStyles.checkboxContainer, {margin:'5px'}]}>
                                    <Text style={PdfStyles.checkboxLabel}>Autorizo el envío por correo electrónico de toda información relacionada con mi vigilancia de la salud.</Text>
                                    <Text style={PdfStyles.checkboxLabel}>SI</Text>
                                    <View style={PdfStyles.checkbox}>
                                        {register.userAuthEmail === true ? 
                                        <Text style={PdfStyles.checkboxChecked}></Text>
                                        : null
                                        } 
                                    </View>
                                    <Text style={PdfStyles.checkboxLabel}>NO</Text>
                                    <View style={PdfStyles.checkbox}>
                                        {register.userAuthEmail === false ? 
                                        <Text style={PdfStyles.checkboxChecked}></Text>
                                        : null
                                        }
                                    </View>
                                </View>
                            </View>
                        </View>
                    </View>
                    
                    <View style={[PdfStyles.comentarios, {width:'100%'}]}>
                        <Text style={PdfStyles.ctext}>
                            {`\r\n\r\n${txt_legal_01}`}
                        </Text>
                    </View>

                    <View style={PdfStyles.signaturesWrapper}>
                        <View style={PdfStyles.signature}>                            
                        </View>
                        <View style={PdfStyles.signature}>                            
                            {register.sign_mode == 2?
                                <Image
                                    style={PdfStyles.signaturePanel}
                                    src={firmante.signature || ''}
                                />
                            :
                                null
                            }
                            <Text style={[PdfStyles.ctext, PdfStyles.ctextMayus]}>{`ESTOY CONFORME EN REALIZAR LAS PRUEBAS PROPUESTAS `}</Text>
                        </View>
                    </View>
                    <View style={PdfStyles.comentarios}>
                        <Text style={PdfStyles.ctext}>{`Fecha: ${GetDatetimeFormat(register.dt_created)}`}</Text>
                    </View>
                </View>
                {/* PDF Footer with page counter*/}
                {/* Footer con texto */}
                {/* <Text style={PdfStyles.footer} render={({ pageNumber, totalPages }) => `Página ${pageNumber} de ${totalPages} | Código: ${informe.code} - Autor: ${informe.author} Fecha de creación: ${formatDate(informe.dtCreated)} `} fixed /> */}
                <Text style={PdfStyles.footer} render={({ pageNumber, totalPages }) => `Página ${pageNumber} de ${totalPages} | ${register.title} - Fecha revisión: ${register.dt_reviewed} `} fixed />

                {/* Imagen en el footer */}
                <Image style={PdfStyles.footerImage2} src={AppNealia_prevent_icon} fixed />
                <Image style={PdfStyles.footerImage} src={AppNealia_prevent} fixed />
            </Page>
        </Document>
    );
}


const questions = [
    { id: "q_1", question: "Autorizo el envío por correo electrónico de toda información relacionada con mi vigilancia de la salud", answer: "", comment: "", a_template: [{ id: "SI", name: "SI" }, { id: "NO", name: "NO" }] },
];

const txt_legal_01 = `Con su autorización, el informe resultante de estas pruebas podrá obtenerlo a través de un enlace con la plataforma documental del Servicio de Prevención Ajeno, con un mensaje que le será enviado a la dirección de correo electrónico que nos ha facilitado, o bien con un link facilitado al teléfono móvil.`;

const txt_legal_02 = `Los marcadores tumorales son sustancias producidas por el organismo como respuesta a una situación benigna o como respuesta a una alteración, un cáncer u otro tipo de patología.\r\n
Es importante que sepa que la detección de estos marcadores tumorales siempre tiene que ir acompañada de una visita a su médico especialista, para que pueda efectuarle una anamnesis clínica enfocada a detectar aquella clínica y factores de riesgo existentes, una buena exploración médica y, cuando así esté indicado por los protocolos médicos, la realización de ciertas pruebas complementarias.\r\n 
Nunca, una sola detección analítica de marcadores tumorales es indicativa de nada por sí sola, ni de existencia de patología, ni de normalidad.\r\n 
Se usan en la clínica médica habitual como una prueba de cribaje precoz de alteraciones. Algunos están asociados con un solo tipo de cáncer, mientras que otros están asociados con dos o más tipos de cáncer. No se ha encontrado un marcador de tumores "universal" que pueda detectar cualquier tipo de cáncer.\r\n
Tal y como ya se ha indicado, existen algunas limitaciones en el uso de marcadores tumorales. Algunas veces, situaciones benignas pueden ir acompañadas de un aumento de las concentraciones de algunos marcadores, sin que tenga una evidencia patológica posterior. Y en otras ocasiones, valores normales de estos marcadores van acompañados de patología, o cáncer, ya que no se han identificado los marcadores para todos los tipos de cáncer.\r\n
La empresa, conjuntamente con el Servicio de Prevención Ajeno, está trabajando en una propuesta de mejora para la salud colectiva de los/las trabajadores/as, ofreciendo una detección de los marcadores tumorales más frecuentes, con un seguimiento periódico a largo plazo. Con la información obtenida, recomendamos encarecidamente el seguimiento posterior con sus especialistas para la valoración de cada caso particular.\r\n
En ningún caso, los datos obtenidos serán facilitados a la empresa, ni serán tenidos en cuenta para la valoración de la aptitud del trabajador en su puesto de trabajo.\r\n
Se iniciará el estudio con la detección de:`;
const txt_legal_01_points = [
    'Tipos de cáncer: cáncer de páncreas, hígado, cáncer de vesícula biliar, cáncer de conducto biliar y cáncer gástrico y colorrectal.',
    'Tejido analizado: Sangre.',
];

interface IFormProps {
    me: IMe;
    classes: any;
    handleCloseItemForm: Function;
    item: IPersonal;
    puestosTrabajo: any;
    trabajadores: any;
    signOptions: any;
    defaultSignId: number;
}

interface IFormState {
    creationModalOpen: boolean;
    creationSignatureOpen: boolean;
    creationSignaturePanel: boolean;
    modalAction: number;
    modalText: any;
    modalType: number;
    titleModal: string;
    htmlBody: boolean;
    loading: boolean;
    loadingModal: boolean;
    register: IXForm;
    m01modalopen: boolean;
    s_firmante?: IFirmante;
    s_firmante_role: boolean;
    bottomreached: boolean;
}

class Form extends Component<IFormProps, IFormState> {
    _going2upload: any = { attachments: [] };
    _form_ref: any = React.createRef();

    constructor(props: IFormProps) {
        super(props);
        this.state = {
            creationModalOpen: false,
            creationSignatureOpen: false,
            creationSignaturePanel: false,
            modalType: -1,
            modalAction: -1,
            modalText: '',
            htmlBody: false,
            loading: false,
            loadingModal: false,
            s_firmante_role: false,
            titleModal: `MENSAJE`,
            register: {
                filename: `Consentimiento_marcadores_tumorales.pdf`,
                sign_mode: props.defaultSignId,
                doctype: 2,
                dt_reviewed: `25/04/18`,
                dt_created: new Date(),
                dt_edited: new Date(),
                title: `Consentimiento marcadores tumorales`,
                usuario: props.me.userIdName,
                userAuthEmail: false,
                m01: [],
                m02: [],
                puestoTrabajo: null,
                comment: "",
                firmantes: [
                    {
                        ...props.item,
                        name: `${props.item.name} ${props.item.surname}`,   
                        onlyName: `${props.item.name}`,
                    }

                    // {
                    //     id: 1,
                    //     name_surname: props.me.userIdName,
                    // },
                    // {
                    //     id: 2,
                    //     name_surname: `${props.item.name} ${props.item.surname}`
                    // }
                ],
                supervisor: {
                    id: `0000000`,
                    name: props.me.userIdName,
                    email: props.me.userId,
                    phone: ''
                },
            },
            m01modalopen: false,
            bottomreached: false
        };
    }

    componentDidMount() {
        this.addlisteners();
    }

    CheckBottomReached = () => {
        return (this._form_ref.current.scrollHeight - this._form_ref.current.scrollTop - this._form_ref.current.clientHeight < 10);
    }

    addlisteners = () => {
        setTimeout(() => {
            if (this._form_ref.current == null) {
                this.addlisteners();
            } else {
                const bottomReached = this.CheckBottomReached();
                if (bottomReached)
                    this.setState({ bottomreached: true });
                this._form_ref.current.addEventListener('scroll', this.isscrolling);
                this._form_ref.current.addEventListener('resize', this.isscrolling);
            }
        }, 150);
    }

    isscrolling = () => {
        const bottomReached = this.CheckBottomReached();
        this.setState({ bottomreached: bottomReached });
    }

    onCloseCreationModal = () => {
        this.setState({ creationModalOpen: false, modalText: undefined, htmlBody: false });
    }

    onCloseFormAddM01 = () => {
        this.setState({ m01modalopen: false });
    }

    onCloseSignatureModal = () => {
        this.setState({ creationSignatureOpen: false });
    }

    onCloseSignaturePanel = () => {
        this.setState({ creationSignaturePanel: false });
    }

    /* CALL BACKEND */

    sendAttachFiles = async () => {
        let response = await OrderService.createImgBlob(this._going2upload, 8);
        this._going2upload = { attachments: [] };
        let p = `Fallo en el proceso, vuelva a intentarlo.`;
        let modaltype = 1;
        let modalaction = -1;

        if (response == 1) {
            p = `Formulario adjuntado correctamente`;
            modaltype = 4;
            modalaction = 1;
        }

        this.setState({
            creationModalOpen: true,
            modalText: p,
            modalType: modaltype,
            modalAction: modalaction,
            loadingModal: false
        });
    }

    /* Callbacks component SignaturePanel */

    handleFirmanteDataChange = (index: number, id: string, firmante: IFirmante) => {
        const { register } = this.state;

        let nregister = cloneDeep(register);

        nregister.firmantes[index][id] = firmante[id];

        this.setState({ register: nregister });
    }

    handleAddSign = (index: number, firmante: IFirmante) => {
        const { register } = this.state;

        let nregister = cloneDeep(register);

        nregister.firmantes[index].signature = firmante.signature;

        this.setState({ register: nregister });
    }

    handleDeleteSign = (index: number, firmante: IFirmante) => {
        const { register } = this.state;

        let nregister = cloneDeep(register);

        nregister.firmantes[index].signature = undefined;

        this.setState({ register: nregister });
    }

    handleAskCreateForm = () => {
        const { item } = this.props;
        this.setState({
            modalAction: 2,
            //modalText: `¡ATENCIÓN! ¿Quiere finalizar el formulario y adjuntarlo al trabajador ${item.id}?`,
            modalText: `¡ATENCIÓN! ¿Quiere finalizar el formulario y adjuntarlo?`,
            modalType: 2,
            creationModalOpen: true,
        });
    }

    //** End callbacks component SignaturePanel */

    handleCreateModal = (typeAction: number) => {
        switch (typeAction) {
            case 1: // Implica que se ha adjuntado corractamente el formulario
                this.props.handleCloseItemForm(true);
                this.onCloseCreationModal();
                break;
            case 2: // Implica iniciar el proceso de adjuntar el formulario
                this.setState({ loadingModal: true }, () => {
                    this.handleCreateForm();
                });
                break;
            default:
                this.onCloseCreationModal();
        }
    }

    handleChangeSelectDevices = (e: any, list: Array<string>) => {
        let nRegister = cloneDeep(this.state.register);
        nRegister.puestoTrabajo = list;
        this.setState({ register: nRegister });
    }


    handleChangeUser = (id: string, e: any) => {
        let nRegister = cloneDeep(this.state.register);
        nRegister[id] = e.target.value;
        this.setState({ register: nRegister });
    }

    handleChangeUserQuestions=(id:any, event:any)=>{
        let nRegister = cloneDeep(this.state.register);
        nRegister.userAuthEmail = event.target.checked;
        this.setState({ register: nRegister });
    }

    handleChangeFirmantes = (e: any, list: Array<any>) => {
        let nRegister = cloneDeep(this.state.register);
        nRegister.firmantes = list;
        this.setState({ register: nRegister });
    }

    handleCreateForm = () => {

        const { item, me } = this.props;
        const { register } = this.state;

        let promises: any = [];

        let t_register = cloneDeep(register);
        t_register.firmantes = [];

        register.firmantes.map((firmante: IFirmante, idx: number) => {
            let dale =
                <PdfForm index={idx} data={[register, me, item, firmante]} style={{}} />

            const blob = pdf(dale).toBlob();

            promises.push(new Promise((resolve, reject) => {
                blob.then(ret => {

                    let reader = new FileReader();

                    reader.onloadend = async () => {
                        this._going2upload.attachments.push({ name: register.filename, sign_mode: register.sign_mode, firmante: firmante, doctype: register.doctype, stream: reader.result, centrotrabajoId: item.centrotrabajoId, tipo: 3, listadoId: 1, id: firmante.id, metadata: t_register });
                        //this.setState({loading:true }, ()=>this.sendAttachFiles());
                        //this.sendAttachFiles();
                        resolve(1);
                    }
                    reader.readAsDataURL(ret);

                }).catch(err => {
                    console.log(err)
                    reject(0)
                })
            }));
        })



        Promise.all(promises)
            .then(results => {
                //console.log(this._going2upload.attachments);
                this.sendAttachFiles();
            })

        // blob.then(ret=>{

        //     let reader = new FileReader();

        //     reader.onloadend = async ()=>{
        //         this._going2upload.attachments.push({name: `Informe_EPIs_03_personal.pdf`, stream: reader.result, centrotrabajoId: item.centrotrabajoId, tipo: 3, listadoId: 1, id: item.id, metadata: register});
        //         //this.setState({loading:true }, ()=>this.sendAttachFiles());
        //         this.sendAttachFiles();
        //     }
        //     reader.readAsDataURL(ret);

        // }).catch(err=>{
        //     console.log(err)
        // })
    }

    verifyForm = (register: IXForm) => {
        // Verifica que por lo menos hay un firmante
        if (register.firmantes.length == 0)
            return false;

        if (!this.state.bottomreached)
            return false;

        return true;
    }

    render() {
        const { register, loading, creationSignaturePanel, s_firmante } = this.state;
        const { me, puestosTrabajo, trabajadores, signOptions } = this.props;

        /*let usersinspected = register.inspections.map((inspection:IIQuestion, index:number)=>{
            if(inspection.used)
                return(this.renderUser(inspection, index, secciones));
        });*/
        return (
            <Dialog style={{ padding: '25px' }} fullScreen={false} open={true} aria-labelledby="form-dialog-title" className='dialog-data'>
                <DialogTitle id="form-dialog-title">
                    <div className="App-header">
                        {`${register.title}`}
                    </div>
                </DialogTitle>
                <DialogContent ref={this._form_ref}>
                    <div>

                        <div style={{ marginBottom: 20 }}>
                            <SelectorField
                                key={0}
                                name={'Método firma'}
                                id={'sign_mode'}
                                deviceData={register}
                                fieldsData={signOptions}
                                handleFieldChange={this.handleChangeUser}
                                required={true}
                                style={1}
                                className='filter-item'
                            />
                        </div>

                        <div style={{ width: '100%', marginTop: 10, marginBottom: 30 }}>
                            <List id={"firmantes"} data={register.firmantes} multiple={true} name={'Trabajador'} placeholder={'Firmantes...'} options={trabajadores} handleChangeSelect={(e: any, list: any) => this.handleChangeFirmantes(e, list)} />
                        </div>
                        <div style={{ width: '100%', marginTop: 10, marginBottom: 30 }}>
                            <CheckBoxField
                                key={'userAuthEmail'}
                                name={"Autorizo el envío por correo electrónico de toda información relacionada con mi vigilancia de la salud"}
                                id={'userAuthEmail'}
                                fieldsData={register}
                                handleFieldChange={(id:string, evt:any)=>this.handleChangeUserQuestions(id,evt)}
                                disabled={false}
                                size="medium"
                                shrink={true}                                
                                type={"5"}
                            />
                        </div>
                        <div>
                            <div className="Form-section">
                                {`Información para el trabajador`}
                            </div>
                            <p style={{whiteSpace:'pre-line', textAlign:'justify'}}>
                                {txt_legal_01}
                            </p>
                        </div>
                        <div>
                            {this.state.creationModalOpen ?
                                <CreateModal
                                    onCloseCreationModal={this.onCloseCreationModal}
                                    isOpen={this.state.creationModalOpen}
                                    inputText={this.state.modalText}
                                    typeModal={this.state.modalType}
                                    action={this.handleCreateModal}
                                    typeAction={this.state.modalAction}
                                    loadingModal={this.state.loadingModal}
                                    titleModal={this.state.titleModal}
                                />
                                :
                                null
                            }

                            {creationSignaturePanel ?
                                <SignaturePanel
                                    handleCloseItemForm={this.onCloseSignaturePanel}
                                    handleAddSign={this.handleAddSign}
                                    handleDeleteSign={this.handleDeleteSign}
                                    handleFirmanteDataChange={this.handleFirmanteDataChange}
                                    firmantes={register.firmantes}
                                    sign_mode={register.sign_mode}
                                    handleAskCreateForm={this.handleAskCreateForm}
                                />
                                :
                                null
                            }
                        </div>
                    </div>
                </DialogContent>
                <DialogActions style={{ borderTop: '#ff972b solid 3px' }}>
                    {!loading ?
                        <div style={{ marginRight: 'auto', display: 'flex', marginLeft: 'auto', width: '90%', justifyContent: 'space-between' }}>
                            <Button onClick={() => this.props.handleCloseItemForm(false)} className="cancel-dialog">
                                {'Cancelar'}
                            </Button>
                            <Button disabled={!this.verifyForm(register)} onClick={() => this.setState({ creationSignaturePanel: true })} className={`confirm-dialog ${!this.verifyForm(register) ? `confirm-dialog-disable` : ``}`}>
                                {`Firmar`}
                            </Button>
                        </div>
                        :
                        <Loader loading={true} />
                    }
                </DialogActions>
            </Dialog>
        );
    }
}

export default withStyles(styles)(Form);