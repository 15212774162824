import React, { Component } from 'react';
import { createStyles, withStyles, Theme } from '@material-ui/core/styles';
import { Text, View, StyleSheet, Image, PDFViewer, PDFDownloadLink, pdf } from '@react-pdf/renderer';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/AddBoxOutlined';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import ViewIcon from '@material-ui/icons/VisibilityOutlined';
import SignIcon from '@material-ui/icons/EditOutlined';
import DatePicker from '../DatePicker';
import { IMe, IPersonal, IXForm, ICentroTrabajo, VIIIQuestion, IFirmante } from '../../types';
import { SelectorField, CheckBoxField, TextFieldInput } from '../FormsWidgets';
import { GetRole, GetEmpresa } from '../../helpers';
import TakeIcon from '@material-ui/icons/CheckCircleOutline';
import { cloneDeep, filter, find, findIndex } from 'lodash';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AddActionIcon from '@material-ui/icons/AddBox';
import IconButton from '@material-ui/core/IconButton';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import Paper from '@material-ui/core/Paper';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import PageViewIcon from '@material-ui/icons/Pageview';
import { GetConcatedDate, GetDatetimeFormat } from '../../vc_general';
import OrderService from '../../ApiClients/OrderService';
import LoaderBeauty from '../Material/activityProgress';
import CreateModal from '../CreateDialog';
import List from '../Material/MultiSelect';
import Loader from '../Loader';
import { ListChildComponentProps } from 'react-window';
import s1 from '../../Assets/widgets/s1.png';
import s2 from '../../Assets/widgets/s2.png';
import s3 from '../../Assets/widgets/s3.png';
import s4 from '../../Assets/widgets/s4.png';
import s5 from '../../Assets/widgets/s5.png';
import s6 from '../../Assets/widgets/s6.png';
import s7 from '../../Assets/widgets/s7.png';
import s8 from '../../Assets/widgets/s8.png';
import { Delete } from '@material-ui/icons';
import { PdfStyles } from './pdfStyles';
import AppNealia_prevent_icon from '../../Assets/nealia_prevent_icon.png';
import AppNealia_prevent from '../../Assets/nealia_prevent.png';
import { Document, Page } from '../Material/pdfRenderComponents';
import SignaturePanel from '../Material/SignatureControl';
import { register } from '../../serviceWorker';

const m01styles = ({
    button: {
        margin: 1,
        marginRight: 15
    },
    leftIcon: {
        marginRight: 10,
    },
    rightIcon: {
        marginLeft: 1,
    },
    iconSmall: {
        fontSize: 20,
    },
    formControl: {
        margin: '1px',
        minWidth: 120,
        marginRight: 'auto',
    },
});

const styles = (theme: Theme) => createStyles({
    container: {
        padding: '10px',
        textAlign: 'center',
        overflowY: 'auto'
    },
    image: {
        width: '100%',
        height: 'auto'
    },
    promotion: {
        //backgroundColor: '#f00',
        padding: '3px 15px',
        //color: '#fff',
        verticalAlign: 'middle',
    },
    root: {
        width: '100%',
    },
    paper: {
        width: '100%',
        marginTop: '5px',
        overflowX: 'auto',
        borderWidth: 2,
        borderColor: '#00809b',
        borderStyle: 'solid',
    },
    table: {
        //tableLayout:'fixed'
        width: '100%',
    },
    row: {
        //wordWrap:'break-word'
        width: '100%',
        //backgroundColor:'#ddd'
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        // fontWeight: theme.typography.fontWeightRegular,
    },
    item: {
        maxWidth: '200px',
        border: '2px solid #ccc',
        borderRadius: '15px',
        margin: '5px 10px',
        width: 'calc(100% - 20px)!important',
        boxShadow: '5px 5px 5px 0px rgba(0,0,0,0.50)',
        cursor: 'pointer',
        paddingTop: '10px',
        paddingBottom: '10px',
        position: 'relative',
    },
});

const useStyles = ({
    page: {
        //display: 'row',
        //backgroundColor: '#E4E4E4',
        margin: 10,
        padding: 10,
        flexGrow: 1,
    },
    body: {
        paddingTop: 15,
        paddingBottom: 40,
        paddingLeft: 15,
    },
    logo: {
        width: 60,
        height: 60,
    },
    logoFito: {
        width: 'auto',
        height: 'auto',
    },
    bussinessData: {
        width: 'auto',
        height: 'auto',
    },
    header: {
        marginTop: 7,
        fontSize: 10
    },
    secondHeader: {
        marginTop: 7,
        fontSize: 11,
        marginBottom: 10
    },
    headerTable: {
        fontSize: 10,
        marginVertical: 'auto',
        padding: 3,
        borderTop: '1pt solid rgba(0,0,0,0.31)',
        borderBottom: '1pt solid rgba(0,0,0,0.31)',
        borderLeft: '1pt solid rgba(0,0,0,0.31)',
    },
    lineTable: {
        fontSize: 9,
        marginVertical: 'auto',
        padding: 3,
    },
    content: {
        marginRight: 15,
        '@media min-width: 400': {
            flexDirection: 'row',
        },
    },
    content02: {
        marginRight: 0,
        '@media min-width: 400': {
            flexDirection: 'row',
        },
    }
});

const PdfForm = (props: ListChildComponentProps) => {
    const register: IXForm = props.data[0];
    const me: IMe = props.data[1];
    const item: IPersonal = props.data[2];
    const firmante: IFirmante = props.data[3];
    console.log(firmante);
    
    const empresa: ICentroTrabajo | undefined = GetEmpresa(item.centrotrabajoId, me);

    return (
        <Document>
            <Page key={1} size="A4" style={PdfStyles.page} >
                <View style={PdfStyles.rheader}>
                    <View style={PdfStyles.rowlogo}>
                        <View style={PdfStyles.mainlogo}>
                            <Image style={PdfStyles.logo1} src={AppNealia_prevent_icon} />
                        </View>
                        <View style={PdfStyles.mainTitle}>
                            <Text>{`${register.title}`}</Text>
                            {empresa ?
                                <Text>{empresa.description}</Text>
                                : null
                            }
                        </View>
                        <View style={PdfStyles.scdlogo}>
                            <Image style={PdfStyles.logo2} src={empresa!.logo} />
                        </View>
                    </View>
                    <View style={PdfStyles.separator}>
                    </View>
                </View>
                <View style={PdfStyles.apartado}>
                    <View style={PdfStyles.titlesec}>
                        <View style={PdfStyles.titletext}><Text><Text style={PdfStyles.numeration}> // </Text>{`Información al trabajador referente a la vigilancia de la salud`}</Text></View>
                    </View>
                    <View style={PdfStyles.comentarios}>
                        <Text style={PdfStyles.ctext}>{`Señor/a,\r\n\r\n\
De acuerdo con lo establecido en el artículo 22 de la Ley de Prevención de Riesgos Laborales, el empresario garantizará a los trabajadores/as a su servicio la vigilancia periódica de su estado de salud, según los riesgos inherentes al puesto de trabajo que ocupe.\r\n\r\n
En este sentido, le informamos de que:\r\n`}</Text>
                    </View>


                    {txt_legal_01_points.map((point: string) => {
                        return (<View style={PdfStyles.comentarios}>
                            <View style={PdfStyles.cspacer}>
                                <View style={PdfStyles.square}></View>
                            </View>
                            <View style={PdfStyles.ctext}><Text>{ point.replace("|Ajeno|", register.servPrevAjeno) }</Text></View>
                        </View>
                        );
                    })}
                    <View style={PdfStyles.comentarios}>
                        <Text style={PdfStyles.ctext}>{`Y para dejar constancia de que ha recibido y comprendido esta información, le rogamos firme y complete los datos siguientes:\r\n\r\n`}</Text>
                    </View>
                    <View style={PdfStyles.tableWrapper}>
                        <View style={PdfStyles.tableRow}>
                            <View style={[PdfStyles.tableCol, PdfStyles.tableColWrapperData, {borderWidth:0}]}>
                                <View style={PdfStyles.table}>
                                    <View style={PdfStyles.tableRow}>
                                        <View style={[PdfStyles.tableCol, PdfStyles.tableColWrapperHeader]}>
                                            <Text style={PdfStyles.tableCell}>Apellidos</Text>
                                        </View>
                                        <View style={PdfStyles.tableCol}>
                                            <Text style={PdfStyles.tableCell}>{`${firmante.surname}`}</Text>
                                        </View>
                                    </View>
                                    <View style={PdfStyles.tableRow}>
                                        <View style={[PdfStyles.tableCol, PdfStyles.tableColWrapperHeader]}>
                                            <Text style={PdfStyles.tableCell}>Nombre</Text>
                                        </View>
                                        <View style={PdfStyles.tableCol}>
                                            <Text style={PdfStyles.tableCell}>{`${firmante.onlyName}`}</Text>
                                        </View>
                                    </View>
                                    <View style={PdfStyles.tableRow}>
                                        <View style={[PdfStyles.tableCol, PdfStyles.tableColWrapperHeader]}>
                                            <Text style={PdfStyles.tableCell}>DNI/NIE</Text>
                                        </View>
                                        <View style={PdfStyles.tableCol}>
                                            <Text style={PdfStyles.tableCell}>{`${firmante.ssn}`}</Text>
                                        </View>
                                    </View>
                                    <View style={PdfStyles.tableRow}>
                                        <View style={[PdfStyles.tableCol, PdfStyles.tableColWrapperHeader]}>
                                            <Text style={PdfStyles.tableCell}>Fecha nacimiento</Text>
                                        </View>
                                        <View style={PdfStyles.tableCol}>
                                            <Text style={PdfStyles.tableCell}>{`${firmante.birhday}`}</Text>
                                        </View>
                                    </View>
                                    <View style={PdfStyles.tableRow}>
                                        <View style={[PdfStyles.tableCol, PdfStyles.tableColWrapperHeader]}>
                                            <Text style={PdfStyles.tableCell}>E-mail</Text>
                                        </View>
                                        <View style={PdfStyles.tableCol}>
                                            <Text style={PdfStyles.tableCell}>{`${firmante.email}`}</Text>
                                        </View>
                                    </View>
                                    <View style={PdfStyles.tableRow}>
                                        <View style={[PdfStyles.tableCol, PdfStyles.tableColWrapperHeader, PdfStyles.tableColLast]}>
                                            <Text style={PdfStyles.tableCell}>Fecha</Text>
                                        </View>
                                        <View style={[PdfStyles.tableCol, PdfStyles.tableColLast]}>
                                            <Text style={PdfStyles.tableCell}>{`${GetDatetimeFormat(register.dt_created)}`}</Text>
                                        </View>
                                    </View>
                                </View>
                            </View>
                            <View style={[PdfStyles.tableCol, PdfStyles.tableColWrapperHeader, {height: '100%'}]}>
                                <View style={PdfStyles.table}>
                                    <View style={[PdfStyles.tableRow, {flexGrow: 0}]}>
                                        <View style={[PdfStyles.tableCol, PdfStyles.tableColNoline, {borderTopWidth:0}]}>
                                            <Text style={PdfStyles.tableCell}>Firma trabajador/a</Text>
                                        </View>
                                    </View>
                                    <View style={PdfStyles.tableRow}>
                                        <View style={[PdfStyles.tableCol, PdfStyles.tableColNoline, PdfStyles.tableColLast]}>
                                            {register.sign_mode == 2 ?
                                                <View style={[PdfStyles.tableFirmContent]}>
                                                    <Image style={PdfStyles.firmPanelCell} src={firmante.signature || ''}/>
                                                </View>
                                                :
                                                <Text style={PdfStyles.tableCellFirm}></Text>
                                            }
                                        </View>
                                    </View>
                                </View>
                            </View>
                        </View>
                    </View>
                </View>
                {/* PDF Footer with page counter*/}
                {/* Footer con texto */}
                {/* <Text style={PdfStyles.footer} render={({ pageNumber, totalPages }) => `Página ${pageNumber} de ${totalPages} | Código: ${informe.code} - Autor: ${informe.author} Fecha de creación: ${formatDate(informe.dtCreated)} `} fixed /> */}
                <Text style={PdfStyles.footer} render={({ pageNumber, totalPages }) => `Página ${pageNumber} de ${totalPages} | ${register.title} - Fecha revisión: ${register.dt_reviewed} `} fixed />

                {/* Imagen en el footer */}
                <Image style={PdfStyles.footerImage2} src={AppNealia_prevent_icon} fixed />
                <Image style={PdfStyles.footerImage} src={AppNealia_prevent} fixed />
            </Page>
        </Document>
    );
}

const txt_legal_01 = `De acuerdo con lo establecido en el artículo 22 de la Ley de Prevención de Riesgos Laborales, el empresario garantizará a los trabajadores/as a su servicio la vigilancia periódica de su estado de salud, según los riesgos inherentes al puesto de trabajo que ocupe.`;


const txt_legal_01_points = [
    'La empresa tiene contratado el servicio de prevención de riesgos laborales a |Ajeno| para ocuparse de las actividades correspondientes a la vigilancia de la salud de los trabajadores.',
    'Los reconocimientos médicos serán realizados por personal sanitario de |Ajeno| con capacidad acreditada.',
    'Los datos referidos a las pruebas médicas realizadas y los resultados obtenidos son confidenciales, por lo que el acceso a la información médica de carácter personal queda limitada al personal médico y a las autoridades sanitarias, sin que se pueda facilitar al empresario/a o a otras personas si el trabajador/a no da su consentimiento expreso.',
    'El empresario/a y las personas responsables en materia de prevención de riesgos laborales serán informados de las conclusiones que se deriven de los reconocimientos efectuados en relación con la aptitud para la ocupación del puesto de trabajo o con la necesidad de introducir o mejorar las medidas de protección y prevención.',
    'La vigilancia de la salud en su lugar de trabajo está sujeta a excepción de voluntariedad y es obligatoria, ya que así está establecido en una disposición legal que regula la protección de riesgos específicos y actividades de especial peligrosidad.',
];

interface IFormProps {
    me: IMe;
    classes: any;
    handleCloseItemForm: Function;
    item: IPersonal;
    puestosTrabajo: any;
    trabajadores: any;
    signOptions: any;
    defaultSignId: number;
}

interface IFormState {
    creationModalOpen: boolean;
    creationSignatureOpen: boolean;
    creationSignaturePanel: boolean;
    modalAction: number;
    modalText: any;
    modalType: number;
    titleModal: string;
    htmlBody: boolean;
    loading: boolean;
    loadingModal: boolean;
    register: IXForm;
    m01modalopen: boolean;
    s_firmante?: IFirmante;
    s_firmante_role: boolean;
    bottomreached: boolean;
}

class Form extends Component<IFormProps, IFormState> {
    _going2upload: any = { attachments: [] };
    _form_ref: any = React.createRef();

    constructor(props: IFormProps) {
        super(props);
        this.state = {
            creationModalOpen: false,
            creationSignatureOpen: false,
            creationSignaturePanel: false,
            modalType: -1,
            modalAction: -1,
            modalText: '',
            htmlBody: false,
            loading: false,
            loadingModal: false,
            s_firmante_role: false,
            titleModal: `MENSAJE`,
            register: {
                filename: `Consentimiento_reconocimiento_medico.pdf`,
                sign_mode: props.defaultSignId,
                doctype: 2,
                dt_reviewed: `25/04/18`,
                dt_created: new Date(),
                dt_edited: new Date(),
                title: `Consentimiento reconocimiento médico`,
                servPrevAjeno: '',
                usuario: props.me.userIdName,
                m01: [],
                m02: [],
                puestoTrabajo: null,
                comment: "",
                firmantes: [
                    {
                        ...props.item,
                        name: `${props.item.name} ${props.item.surname}`
                    }

                    // {
                    //     id: 1,
                    //     name_surname: props.me.userIdName,
                    // },
                    // {
                    //     id: 2,
                    //     name_surname: `${props.item.name} ${props.item.surname}`
                    // }
                ],
                supervisor: {
                    id: `0000000`,
                    name: props.me.userIdName,
                    email: props.me.userId,
                    phone: ''
                },
            },
            m01modalopen: false,
            bottomreached: false
        };
    }

    componentDidMount() {
        this.addlisteners();
    }

    CheckBottomReached = () => {
        return (this._form_ref.current.scrollHeight - this._form_ref.current.scrollTop - this._form_ref.current.clientHeight < 10);
    }

    addlisteners = () => {
        setTimeout(() => {
            if (this._form_ref.current == null) {
                this.addlisteners();
            } else {
                const bottomReached = this.CheckBottomReached();
                if (bottomReached)
                    this.setState({ bottomreached: true });
                this._form_ref.current.addEventListener('scroll', this.isscrolling);
                this._form_ref.current.addEventListener('resize', this.isscrolling);
            }
        }, 150);
    }

    isscrolling = () => {
        const bottomReached = this.CheckBottomReached();
        this.setState({ bottomreached: bottomReached });
    }

    onCloseCreationModal = () => {
        this.setState({ creationModalOpen: false, modalText: undefined, htmlBody: false });
    }

    onCloseFormAddM01 = () => {
        this.setState({ m01modalopen: false });
    }

    onCloseSignatureModal = () => {
        this.setState({ creationSignatureOpen: false });
    }

    onCloseSignaturePanel = () => {
        this.setState({ creationSignaturePanel: false });
    }

    /* CALL BACKEND */

    sendAttachFiles = async () => {
        let response = await OrderService.createImgBlob(this._going2upload, 8);
        this._going2upload = { attachments: [] };
        let p = `Fallo en el proceso, vuelva a intentarlo.`;
        let modaltype = 1;
        let modalaction = -1;

        if (response == 1) {
            p = `Formulario adjuntado correctamente`;
            modaltype = 4;
            modalaction = 1;
        }

        this.setState({
            creationModalOpen: true,
            modalText: p,
            modalType: modaltype,
            modalAction: modalaction,
            loadingModal: false
        });
    }

    /* Callbacks component SignaturePanel */

    handleFirmanteDataChange = (index: number, id: string, firmante: IFirmante) => {
        const { register } = this.state;

        let nregister = cloneDeep(register);

        nregister.firmantes[index][id] = firmante[id];

        this.setState({ register: nregister });
    }

    handleAddSign = (index: number, firmante: IFirmante) => {
        const { register } = this.state;

        let nregister = cloneDeep(register);

        nregister.firmantes[index].signature = firmante.signature;

        this.setState({ register: nregister });
    }

    handleDeleteSign = (index: number, firmante: IFirmante) => {
        const { register } = this.state;

        let nregister = cloneDeep(register);

        nregister.firmantes[index].signature = undefined;

        this.setState({ register: nregister });
    }

    handleAskCreateForm = () => {
        const { item } = this.props;
        this.setState({
            modalAction: 2,
            //modalText: `¡ATENCIÓN! ¿Quiere finalizar el formulario y adjuntarlo al trabajador ${item.id}?`,
            modalText: `¡ATENCIÓN! ¿Quiere finalizar el formulario y adjuntarlo?`,
            modalType: 2,
            creationModalOpen: true,
        });
    }

    //** End callbacks component SignaturePanel */

    handleCreateModal = (typeAction: number) => {
        switch (typeAction) {
            case 1: // Implica que se ha adjuntado corractamente el formulario
                this.props.handleCloseItemForm(true);
                this.onCloseCreationModal();
                break;
            case 2: // Implica iniciar el proceso de adjuntar el formulario
                this.setState({ loadingModal: true }, () => {
                    this.handleCreateForm();
                });
                break;
            default:
                this.onCloseCreationModal();
        }
    }

    handleChangeSelectDevices = (e: any, list: Array<string>) => {
        let nRegister = cloneDeep(this.state.register);
        nRegister.puestoTrabajo = list;
        this.setState({ register: nRegister });
    }


    handleChangeUser = (id: string, e: any) => {
        let nRegister = cloneDeep(this.state.register);
        nRegister[id] = e.target.value;
        this.setState({ register: nRegister });
    }

    handleChangeFirmantes = (e: any, list: Array<any>) => {
        let nRegister = cloneDeep(this.state.register);
        nRegister.firmantes = list;
        this.setState({ register: nRegister });
    }

    handleCreateForm = () => {

        const { item, me } = this.props;
        const { register } = this.state;

        let promises: any = [];

        let t_register = cloneDeep(register);
        t_register.firmantes = [];

        register.firmantes.map((firmante: IFirmante, idx: number) => {
            let dale =
                <PdfForm index={idx} data={[register, me, item, firmante]} style={{}} />

            const blob = pdf(dale).toBlob();

            promises.push(new Promise((resolve, reject) => {
                blob.then(ret => {

                    let reader = new FileReader();

                    reader.onloadend = async () => {
                        this._going2upload.attachments.push({ name: register.filename, sign_mode: register.sign_mode, firmante: firmante, doctype: register.doctype, stream: reader.result, centrotrabajoId: item.centrotrabajoId, tipo: 3, listadoId: 1, id: firmante.id, metadata: t_register });
                        //this.setState({loading:true }, ()=>this.sendAttachFiles());
                        //this.sendAttachFiles();
                        resolve(1);
                    }
                    reader.readAsDataURL(ret);

                }).catch(err => {
                    console.log(err)
                    reject(0)
                })
            }));
        })



        Promise.all(promises)
            .then(results => {
                //console.log(this._going2upload.attachments);
                this.sendAttachFiles();
            })

        // blob.then(ret=>{

        //     let reader = new FileReader();

        //     reader.onloadend = async ()=>{
        //         this._going2upload.attachments.push({name: `Informe_EPIs_03_personal.pdf`, stream: reader.result, centrotrabajoId: item.centrotrabajoId, tipo: 3, listadoId: 1, id: item.id, metadata: register});
        //         //this.setState({loading:true }, ()=>this.sendAttachFiles());
        //         this.sendAttachFiles();
        //     }
        //     reader.readAsDataURL(ret);

        // }).catch(err=>{
        //     console.log(err)
        // })
    }

    verifyForm = (register: IXForm) => {
        // Verifica que por lo menos hay un firmante
        if (register.firmantes.length == 0)
            return false;

        if (register.servPrevAjeno.length == 0)
            return false;

        if (!this.state.bottomreached)
            return false;

        return true;
    }

    render() {
        const { register, loading, creationSignaturePanel, s_firmante } = this.state;
        const { me, puestosTrabajo, trabajadores, signOptions } = this.props;

        /*let usersinspected = register.inspections.map((inspection:IIQuestion, index:number)=>{
            if(inspection.used)
                return(this.renderUser(inspection, index, secciones));
        });*/

        return (
            <Dialog style={{ padding: '25px' }} fullScreen={false} open={true} aria-labelledby="form-dialog-title" className='dialog-data'>
                <DialogTitle id="form-dialog-title">
                    <div className="App-header">
                        {`${register.title}`}
                    </div>
                </DialogTitle>
                <DialogContent ref={this._form_ref}>
                    <div>

                        <div style={{ marginBottom: 20 }}>
                            <SelectorField
                                key={0}
                                name={'Método firma'}
                                id={'sign_mode'}
                                deviceData={register}
                                fieldsData={signOptions}
                                handleFieldChange={this.handleChangeUser}
                                required={true}
                                style={1}
                                className='filter-item'
                            />
                        </div>
                        <div style={{ marginBottom: 20 }}>
                            <TextFieldInput
                                key={1}
                                name={'Servicio de Prevención Ajeno'}
                                id={'servPrevAjeno'}
                                fieldsData={register}
                                handleFieldChange={this.handleChangeUser}
                                disabled={false}
                                size="medium"
                                shrink={true}
                                type={'1'}
                                required={true}
                                style={3}
                                helper={''}
                                maxText={100}
                                noError={true}
                                multiline={false}
                            />
                        </div>
                        <div style={{ width: '100%', paddingTop: 20, marginBottom: 30 }}>
                            <List id={"firmantes"} data={register.firmantes} multiple={true} name={'Trabajador'} placeholder={'Firmantes...'} options={trabajadores} handleChangeSelect={(e: any, list: any) => this.handleChangeFirmantes(e, list)} />
                        </div>
                        <div>
                            <div className="Form-section">
                                {`Información para el trabajador`}
                            </div>
                            <p style={{whiteSpace:'pre-line', textAlign:'justify'}}>
                                {txt_legal_01}
                            </p>
                            <p>En este sentido, le informamos de que:</p>
                            <ul>
                                {txt_legal_01_points.map((point: string) => {
                                    return (
                                    <li >                                        
                                        { point }
                                    </li>
                                    );
                                })}
                            </ul>
                        </div>
                        <div>
                            {this.state.creationModalOpen ?
                                <CreateModal
                                    onCloseCreationModal={this.onCloseCreationModal}
                                    isOpen={this.state.creationModalOpen}
                                    inputText={this.state.modalText}
                                    typeModal={this.state.modalType}
                                    action={this.handleCreateModal}
                                    typeAction={this.state.modalAction}
                                    loadingModal={this.state.loadingModal}
                                    titleModal={this.state.titleModal}
                                />
                                :
                                null
                            }

                            {creationSignaturePanel ?
                                <SignaturePanel
                                    handleCloseItemForm={this.onCloseSignaturePanel}
                                    handleAddSign={this.handleAddSign}
                                    handleDeleteSign={this.handleDeleteSign}
                                    handleFirmanteDataChange={this.handleFirmanteDataChange}
                                    firmantes={register.firmantes}
                                    sign_mode={register.sign_mode}
                                    handleAskCreateForm={this.handleAskCreateForm}
                                />
                                :
                                null
                            }
                        </div>
                    </div>
                </DialogContent>
                <DialogActions style={{ borderTop: '#ff972b solid 3px' }}>
                    {!loading ?
                        <div style={{ marginRight: 'auto', display: 'flex', marginLeft: 'auto', width: '90%', justifyContent: 'space-between' }}>
                            <Button onClick={() => this.props.handleCloseItemForm(false)} className="cancel-dialog">
                                {'Cancelar'}
                            </Button>
                            <Button disabled={!this.verifyForm(register)} onClick={() => this.setState({ creationSignaturePanel: true })} className={`confirm-dialog ${!this.verifyForm(register) ? `confirm-dialog-disable` : ``}`}>
                                {`Firmar`}
                            </Button>
                        </div>
                        :
                        <Loader loading={true} />
                    }
                </DialogActions>
            </Dialog>
        );
    }
}

export default withStyles(styles)(Form);